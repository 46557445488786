import React from "react";

import styled from "styled-components";
import axios from "axios";

const Background = styled.div `  

    width: 30%;
    height: 100%;

    min-width: 300px;
`

const BottomBackground = styled.div `
    border: solid 3px black;
    margin-bottom: 10px;
`

class AllofSales extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            todays_income : '0',
            card_income_sum : '0'
        }        
    }

    componentDidMount() {
        this.getAllofSales()
    }

    getDate = () => {
    let date = new Date()

    let year = date.getFullYear()
    let month = String(date.getMonth() + 1).padStart(2,'0')
    let todayDate = String(date.getDate()).padStart(2, '0')    
    const week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
    let dayweek = week[date.getDay()]

    return year + '.' + month  + '.' + todayDate + ' ' + dayweek
    }

    getAllofSales = () => {
        let bodyData = {
            shop_id : "부평점",    
            start_date : "2023-01-01"
        }
        
        axios.post('/api/getAllofSales', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            let todays_income = data[1][0].todays_amount
            let card_income_sum = data[2][0].card_amount

            this.setState({
                todays_income : String(todays_income),
                card_income_sum : String(card_income_sum),
            });
        });
    }

    render() {
        return (
            <>
                <Background>
                    <p>카드총액 </p>
                    <p>{this.getDate()} 기준</p>
                    <hr/>
                    <p>{this.state.card_income_sum}₩</p>
                    <p>오늘매출 </p>
                    <p>₩{this.state.todays_income}</p>

                </Background>
            </>
        )
    }
}

export default AllofSales