import React, {useEffect, useState, useContext, useRef} from "react";

import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import Select from "react-select"
import DatePicker from "react-datepicker"

import { ko } from "date-fns/esm/locale"
import Pagination from "react-js-pagination"
import { ExportToCsv } from "export-to-csv";
import Swal from 'sweetalert2';
import axios from "axios";
import cookie from 'react-cookies'

import TodaysSale from "../Component/Widget/TodaysSale";
import DailySales from "../Component/Widget/DailySales";
import MonthlySales from "../Component/Widget/MonthlySales";
import TopBar from "../Component/SideBar/TopBar";
import Header from "../Component/Header/Header";
import CategoryContext from "../contexts/category";

import { useMediaQuery } from "react-responsive";

const WidgetArea = styled.div `
padding: 10px;
display: grid;
grid-template-columns: ${ (p) => `repeat( auto-fit, minmax( ${p.minWidth ||'300px'}, 1fr ) )`};
grid-gap: 5px;
background-color: white;
`
const ControlboxArea = styled.div `
padding: 0 0px;
display: grid;
grid-template-columns: ${ (p) => `repeat( auto-fit, minmax( ${p.minWidth ||'300px'}, 1fr ) )`};
grid-gap: 5px;
background-color: white;
`
const ControlboxAreaChild = styled.div `
min-width:300px;
display: flex;
justify-content: space-between;
flex-direction: row;
text-align: center;
`
const TableBoxArea = styled.div `
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
`
const AmountArea = styled.div `
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ; 

    @media screen and (max-width: 766px ) {
        grid-template-columns: 1fr 1fr; 
    }
    
    margin: 0px ;
`
const AmountBox = styled.div `
    display: flex;
    flex-flow:column;
    background-image: linear-gradient(0deg,#EEEEEE,#AAAAAA);
    height:50px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;   
    margin: 5px 5px;  
`
const Amounttitle = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;   
    width:100%;
    height: 18px;  
    font-size: 12px;
    color: #555555;
`
const Amountbar = styled.div `
    width:70%;
    height: 1px;  
    background-color: #999999;
`
const AmountValue = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;   
    width:90%;
    height: 20px;  
    font-size: 16px;    
    font-weight: 600;
    color: #448844;
    margin: 0;
`

const CustomSelect = styled(Select) `
    flex: auto;
    margin: 0 5px;
    text-align: center; 
    line-height: 20px;
`
 const CustomButton = styled.button `
    color: white;
    flex: auto;
    margin: 0 5px;      
    border: 1px solid rgba(100,100,100,0.21);
    border-radius: 4px;
    background-color: rgb(102, 162, 118);
    &:hover  {     background-color: rgb(102, 200, 140); cursor:pointer; };
    &:active {     background-color: rgb(44, 100, 60);  };
 `

const CustomDatePicker = styled(DatePicker) `
    display: flex;
    flex: auto;
    margin: 0 5px;
    height: 35px;
    line-height: 20px;
    padding:0 auto;
    border: solid 2px hsl(0, 100%, 80%);
    font-size: 14px;
    background-color: white;
    color: #707070;
    border-radius: 4px 4px 4px 4px;
    text-align: center;
`
 const SearchButton = styled.button `
    color: white;    
    flex: auto;
    width: 50%;
    margin: 0 5px;   
    border-radius: 4px;
    border: 1px solid rgba(100,100,100,0.21);
    background-color: rgb(102, 118, 162);    
    &:hover  { background-color: rgb(102, 140, 200); cursor:pointer;  };
    &:active { background-color: rgb(44, 60, 100);  };
 `
 const ExcelButton = styled.button `
    color: white; 
    flex: auto;
    width: 50%;
    margin: 0 5px; 
    border-radius: 4px;
    border: 1px solid rgba(100,100,100,0.21);
    background-color: rgb(162, 80, 80);    
    &:hover  { background-color: rgb(200, 80, 80); cursor:pointer; };
    &:active { background-color: rgb(140, 60, 44);  };
`

const PC_Tbl  = ({children}) => {
    const isPc = useMediaQuery({ query: "(min-width:768px)"});
    return <React.Fragment>{ isPc && children }</React.Fragment>
}

const Mobile_Tbl  = ({children}) => {
    const isMobile = useMediaQuery({ query: "(max-width:767px)"});
    return <React.Fragment>{ isMobile && children }</React.Fragment>
}


const SalesStatus = (props) => {
    const navigate = useNavigate(); 
    const { state  } = useContext( CategoryContext);
    var   page = 1 ; 

    const [active_page, setActivePage] = useState(1);
    const [kiosk_arr, setKioskArr] = useState([])
    const [kiosk_id, setKioskId] = useState("all")
    const [numOfColumnArr, setNumOfColumnArr] = useState([])
    const [numOfData, setNumofData] = useState(0);
    const [totalIncome, setTotalIncome] = useState(0);
    const [cashIncome, setCashIncome] = useState(0);
    const [creditIncome, setCreditIncome] = useState(0);
    const [numOfColumn, setNumOfColumn] = useState(15)


    const [salesStatusData, setSalesStatusData] = useState([])
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const selectInputRef = useRef(null)


    const authCheck = () => 
    {  
        let user_id  = cookie.load('user_id');  
        let user_name = cookie.load('name');
        let user_type = cookie.load('type');
        let shop_id   = cookie.load('shop_id');  
        if ( user_id  === undefined )
        {
            navigate('/login');
        }
        else
        {
            cookie.save('user_id',user_id , { path: '/',maxAge : 3600 });
            cookie.save('name',user_name , { path: '/',maxAge : 3600});
            cookie.save('type',user_type , { path: '/',maxAge : 3600});
            cookie.save('shop_id',shop_id, { path: '/',maxAge : 3600});
        }
    }

    useEffect( () => { authCheck(); },[]);

    useEffect(() => {   
       getKioskList()
       setNumOfColumnList()
    }, [state])




    const getKioskList = () => {
        let bodyData = {
            user_id : state.g_user_id,  
            shop_id : state.g_shop_id,
        }

        axios.post('/api/getSalesStatusKioskList', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            var kiosk_arr = []

            kiosk_arr.push({ value: 'all', label: '모든 키오스크' })

            for(var i = 0; i < data.length; i++) {
                var kiosk = {
                    value : data[i].kiosk_id,
                    label : data[i].kiosk_name
                };
                kiosk_arr.push(kiosk);
            }

            setKioskArr(kiosk_arr)
        })
    }

    const getSalesStatus = () => {
        let bodyData = {
            user_id : state.g_user_id,  
            shop_id : state.g_shop_id,
            start_date : startDate.toISOString().slice(0, 10),
            end_date : endDate.toISOString().slice(0, 10),
            limit_num : numOfColumn,
            page_num : page-1,
            kiosk_id : kiosk_id
        }

        axios.post('/api/getSalesStatus', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            if ( data[0][0].data_count !== null )
                setNumofData(data[0][0].data_count);
            else
                setNumofData(0);

            if ( data[0][0].total_amount !== null )
                setTotalIncome(data[0][0].total_amount); 
            else
                setTotalIncome(0); 

            if ( data[0][0].cash_amount !== null )
                setCashIncome(data[0][0].cash_amount); 
            else
                setCashIncome(0); 

            if ( data[0][0].card_amount !== null )                
                setCreditIncome(data[0][0].card_amount); 
            else
                setCreditIncome(0); 
            
            //console.log( data[0][0].data_count);
            setSalesStatusData(data[1])
        })
    }


    const  getSalesStatusExcel = async () => {
        let bodyData = {
            user_id : state.g_user_id,  
            shop_id : state.g_shop_id,
            start_date : startDate.toISOString().slice(0, 10),
            end_date : endDate.toISOString().slice(0, 10),
            limit_num : 100000,
            page_num : 0,
            kiosk_id : kiosk_id
        }

        let response = await axios.post('/api/getSalesStatus', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })

        let data = await response.data;
        return data[1] ;        
    }



    const  showSalesList = () => {
             page = 1;
             getSalesStatus( );    
             setActivePage(page);   
        }


    const downExcel = async () => {    
        
         let excel_data =  await getSalesStatusExcel();

         if ( excel_data.length < 1 )
         {
            Swal.fire({
                title: '엑셀다운로드',
                text: '다운로드할 결과가 없습니다.',
                icon: 'warning',
                confirmButtonText: '닫기'
              })            
            return ;
         }

         let file_name = `sales_report_${startDate.toISOString().slice(0, 10)}_${endDate.toISOString().slice(0, 10)}`;
      
         const options = { 
            filename : file_name,
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: true,
            title: file_name,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
          };         
          const csvExporter = new ExportToCsv(options);           
          csvExporter.generateCsv(excel_data);
    }
    
    
    const setNumOfColumnList = () => {
        var numOfColumn = []  
        
        numOfColumn.push(
            {value : 15,  label : "Show 15"},
            {value : 30,  label : "Show 30"},
            {value : 50,  label : "Show 50"},
            {value : 100, label : "Show 100"}
        )
        setNumOfColumnArr(numOfColumn)
    }

    const handlePageChange = (newpage) => {
        page = newpage;
        getSalesStatus();
        setActivePage(newpage);        
      }

    const setDayRange = ( days ) => 
    {
        var end_date   = new Date();
        var start_date = new Date();
        setEndDate(end_date);    
        var dayOfMonth = start_date.getDate();
        start_date.setDate(dayOfMonth - days );
        setStartDate(start_date);
    }

    const setMonthRange = ( ) => 
    {
        var end_date   = new Date();
        var start_date = new Date();
        setEndDate(end_date);    
        start_date.setDate( 1 );
        setStartDate(start_date);
    }
  

    return (
        <>
        <Header/>
        <TopBar/>
        <div className="main_container" >       
            <WidgetArea>
                <TodaysSale/>
                <DailySales/>
                <MonthlySales/>
            </WidgetArea>
            <ControlboxArea>
                <ControlboxAreaChild>
                <CustomSelect ref={selectInputRef} onChange={(e) => {
                        setKioskId(e.value)
                    }}
                    options={kiosk_arr}
                    placeholder="키오스크 선택"
                    value = {kiosk_arr.filter(function (option) {
                        return option.value === kiosk_id
                    })}
                />
                <CustomSelect ref={selectInputRef} onChange={(e) => {
                        setNumOfColumn(e.value)
                    }}
                    options={numOfColumnArr}
                    placeholder="데이터 갯수"
                    value = {numOfColumnArr.filter(function (option) {
                        return option.value === numOfColumn
                    })}
                />
                </ControlboxAreaChild>
                <ControlboxAreaChild>
                <CustomButton  onClick={ () => setDayRange(1)}>  오늘 </CustomButton>  
                <CustomButton  onClick={ () => setDayRange(7)}>  1주일 </CustomButton>
                <CustomButton  onClick={ () => setDayRange(30)}>  30일 </CustomButton>
                <CustomButton  onClick={ () => setMonthRange()}>  이번달 </CustomButton>   
                </ControlboxAreaChild>  
                <ControlboxAreaChild>
                <CustomDatePicker
                    locale = {ko}
                    closeOnScroll = {true}
                    placeholderText = "처음 날짜"
                    selected = {startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="yyyy-MM-dd"
                />

                <h2>~</h2>

                <CustomDatePicker
                    locale = {ko}
                    closeOnScroll = {true}
                    placeholderText = "마지막 날짜"
                    selected = {endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="yyyy-MM-dd"
                />
                </ControlboxAreaChild>
                <ControlboxAreaChild>
                <SearchButton  onClick={showSalesList}> 검색 </SearchButton>  
                <ExcelButton  onClick={downExcel}> Excel Down </ExcelButton>  
                </ControlboxAreaChild>

            </ControlboxArea>

            <AmountArea>
                 <AmountBox> 
                    <Amounttitle> 조회결과 </Amounttitle> 
                    <Amountbar/>
                    <AmountValue> {numOfData} 건 </AmountValue> 
                </AmountBox>                
                <AmountBox> 
                    <Amounttitle> 총수입 </Amounttitle> 
                    <Amountbar/>
                    <AmountValue> ₩ {totalIncome.toLocaleString()} </AmountValue> 
                </AmountBox>

                <AmountBox> 
                    <Amounttitle> 현 금 </Amounttitle> 
                    <Amountbar/>
                    <AmountValue> ₩ {cashIncome.toLocaleString()} </AmountValue> 
                </AmountBox>                    
                        
                <AmountBox> 
                    <Amounttitle> 카 드 </Amounttitle> 
                    <Amountbar/>
                    <AmountValue> ₩ {creditIncome.toLocaleString()} </AmountValue> 
                </AmountBox>      
            </AmountArea>  

            <TableBoxArea>
                <PC_Tbl>
                <table style={{width: "98%", borderTop: "solid 2px lightgray"}}>
                    <thead>
                        <tr>
                            <th style={{width:"50px"}}> 순번 </th>
                            <th>지점명</th>
                            <th>장치ID</th>
                            <th>장치명</th>
                            <th style={{width:"120px"}}>매출일시</th>
                            <th>결제금액</th>
                            <th>결제방법</th>                            
                            <th>쿠폰</th>
                            <th>포인트</th>
                            <th>프레임</th>
                            <th>출력매수</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {salesStatusData.map((item, index) => (
                            <tr key={index}>
                                <td>{(active_page-1)*numOfColumn + index +1}</td>
                                <td>{item.shop_name}</td>
                                <td>{item.kiosk_id}</td>
                                <td>{item.kiosk_name}</td>
                                <td>{item.tr_time}</td>
                                <td>{item.amount.toLocaleString()}</td>
                                <td>{item.p_type}</td>
                                <td>{item.coupon.toLocaleString()}</td>        
                                <td>{item.point.toLocaleString()}</td>                                      
                                <td>{item.frame}</td>    
                                <td>{item.print_cnt}</td>                      
                            </tr>
                        ))}
                    </tbody>
                </table>
                </PC_Tbl>

                <Mobile_Tbl>

                <table style={{ borderTop: "solid 2px lightgray"}}>
                    <thead>
                        <tr>
                           <th style={{width:"15%"}}> (순번)<br/>지점명</th>   
                            <th style={{width:"23%"}}>장치명<br/>(장치ID)</th>
                            <th style={{width:"12%"}}>매출날짜<br/>시간</th>
                            <th style={{width:"10%"}}>결제금액<br/>결재수단</th>
                            <th style={{width:"10%"}}>쿠  폰<br/>포인트</th>
                            <th style={{width:"10%"}}>프레임<br/>수 량</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesStatusData.map((item, index) => (
                            <tr key={index}>
                                <td style={{backgroundColor: "#EEEEEE"}}>({(active_page-1)*numOfColumn + index +1})<br/>{item.shop_name}  </td>                          
                                <td style={{backgroundColor: "#FFFFFF",color: "darkblue"}}>{item.kiosk_name} <br/> ({item.kiosk_id})</td>
                                <td style={{backgroundColor: "#FFFFFF",color: "black"}} >{item.tr_time.substr(0,11)}<br/>{item.tr_time.substr(11)}</td>
                                <td style={{backgroundColor: "#FFFFFF",color: "red"}} >{item.amount.toLocaleString()}원<br/>{item.p_type} </td>
                                <td style={{backgroundColor: "#FFFFFF",color: "black"}}>{item.coupon.toLocaleString()}<br/> {item.point.toLocaleString()}</td>          
                                <td style={{backgroundColor: "#FFFFFF",color: "green"}}>{item.frame}<br/>{item.print_cnt}장</td>                          
                            </tr>
                        ))}
                    </tbody>
                </table>

                </Mobile_Tbl>
            </TableBoxArea>


             <Pagination
                activePage={active_page}
                itemsCountPerPage={numOfColumn} 
                totalItemsCount={numOfData} 
                pageRangeDisplayed={10} 
                prevPageText={"‹"} 
                nextPageText={"›"} 
                onChange={handlePageChange} 
                />  
       

            </div>
        </>
    )
};

export default SalesStatus
