import { createContext , useState } from 'react';
import cookie  from 'react-cookies'

const CategoryContext = createContext ( {
    state: { g_user_id :'', g_shop_id: '',g_user_name:'', g_user_type:'' },
    actions: {
        setUser : () => {},
        setShop: () => {},
        setUserName: () => {},
        setUserType: () => {}
    }  
 } );

 const CategoryProvider = ({ children}) => {
    const user_id     = cookie.load('user_id');
    const shop_id     = cookie.load('shop_id');
    const user_name   = cookie.load('name');
    const user_type   = cookie.load('type');
    const [ g_user_id,    setUser] = useState(user_id);
    const [ g_shop_id,    setShop] = useState(shop_id);
    const [ g_user_name,  setUserName] = useState(user_name);
    const [ g_user_type,  setUserType] = useState(user_type);

    const value = {
        state :  { g_user_id , g_shop_id, g_user_name, g_user_type},
        actions: { setUser , setShop, setUserName, setUserType}
    };

    return (
        <CategoryContext.Provider value= {value}>{children}</CategoryContext.Provider>
    );
 };



 export { CategoryProvider}; 

export default CategoryContext;
