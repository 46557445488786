import React from "react";

import { BrowserRouter, Routes, Route  } from "react-router-dom";



import Home from "../Pages/Home";
import SalesManagement from "../Pages/Sales_Management";
import SalesStatus from "../Pages/Sales_Status";
import DeviceManagement from "../Pages/Device_Management";
import Settings from "../Pages/Settings";
import Login from "../Pages/LoginForm";

import '../App.css';

export default function Router() {
  return (
    <BrowserRouter>   
        <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route path="/sales_management" element={<SalesManagement/>} />
            <Route path="/sales_status" element={<SalesStatus/>} />
            <Route path="/device_management" element={<DeviceManagement/>} />
            <Route path="/settings" element={<Settings/>} />
            <Route path="/login" element={<Login/>} />
        </Routes>
    </BrowserRouter>
  );
}
