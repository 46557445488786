import React, { useEffect, useState } from "react"
import axios from "axios"
import cookie from 'react-cookies'
import Swal from 'sweetalert2';
import { PersonCircle, Person, Key} from 'react-bootstrap-icons';

 

const LoginForm = (props) => {
    const [id, setID] = useState("")
    const [password, setPassword] = useState("")

    const LoginFunc = (e) => {
      e.preventDefault();
      if (!id) {
        sweetalert("ID를 입력하세요.","",'error','닫기');
        return false; 
      }
      else if (!password) {
        sweetalert("Password를 입력하세요.","",'error','닫기');
        return false;         

      }
      else {
        LoginApi()
      }
    }

    const KeyFunc = (e) => {
      if(e.key === 'Enter') 
      {
        LoginFunc(e);
      }
    }


    useEffect( () => { 
      cookie.remove('user_id');  
      cookie.remove('name');  
      cookie.remove('type');  
      cookie.remove('shop_id');  
    },[]);

    const sweetalert = (title, contents, icon, confirmButtonText) => {
      Swal.fire({
          title: title,
          text: contents,
          icon: icon,
          confirmButtonText: confirmButtonText
        })
    }

    
    const LoginApi = () => {
      let bodyData = {
        user_id: id,
        user_pw: password
      }

      axios.post('/api/getUserLoginStatus', JSON.stringify(bodyData), {
          headers: {
            'Content-Type': 'application/json'
          }
      })
      .then(({ data }) => {
        console.log(data[0].user_id );
        console.log(data[0].name );
        console.log(data[0].type );
        cookie.save('user_id',data[0].user_id , { path: '/',maxAge : 3600 });
        cookie.save('name',data[0].name , { path: '/',maxAge : 3600});
        cookie.save('type',data[0].type , { path: '/',maxAge : 3600});
        cookie.save('shop_id','all', { path: '/',maxAge : 3600});
        document.location.href = '/'
      })
      .catch( error => {sweetalert('로그인하지 못하였습니다.\n아이디 및 패스워드 확인 바랍니다.', error, 'error', '닫기');return false;} );

    }

    return (
        <>
        <section className="main">
          <form className="m_login" onKeyDown={KeyFunc}>
            <h3><span> <PersonCircle /> </span>LOGIN</h3>
            <div className="log_box">
              <div className="in_type">
                <span > <Person className="icon"/></span>
                <input value={id} id="id" type="text" onChange={(e) => setID(e.target.value)} placeholder="user id "/>
              </div>

              <div className="in_type">
                <span >  <Key className="icon" /></span>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password" />
              </div>
              <div className="s_bt" onClick={LoginFunc}>Login</div>
            </div>
          </form>
        </section>
        </>
    )
};

export default LoginForm
