import React , { useContext, useEffect , useState }from "react";

import styled from "styled-components";
import { ResponsiveLine } from '@nivo/line' 
import axios from "axios";
import CategoryContext from "../../contexts/category";

const Background = styled.div `
height: 200px;
min-height: 0;
min-width: 0 ; 

border: solid 1px lightgray;
box-shadow: 5px 5px 10px lightgray;
border-radius: 15px;
padding: 10px;
min-width:300px;
`
const DailySales = (props) => {
  const { state  } = useContext( CategoryContext);
  const [ sales_data, setSalesData] = useState([]);
  useEffect( () => { getDailysSales(); },[ state]);


  const getDailysSales = () => {
        let bodyData = {
          user_id : state.g_user_id,  
          shop_id : state.g_shop_id,
        }
        
        axios.post('/api/getDailySales', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
          var dateArr = []
          var amountArr = []
          var salesArr = []

          for (var i = 6; i >= 0 ; i--) {
            let date = new Date()
            date.setDate(date.getDate() - i)

            let month = ('0' + (date.getMonth() + 1)).slice(-2)
            let day = ('0' + (date.getDate())).slice(-2)

            dateArr.push(`${month}.${day}`)
            amountArr.push(0)
          }

          for (var i = 0; i < data[0].length; i++) {
            var date = new Date(data[0][i].date)

            let month = ('0' + (date.getMonth() + 1)).slice(-2)
            let day = ('0' + (date.getDate())).slice(-2)

            var tmpDate = `${month}.${day}`
            var tmpDataArrIdx = dateArr.indexOf(tmpDate)

            amountArr[tmpDataArrIdx] = data[0][i].total_amount
          }

          for (var i = 0; i < dateArr.length; i++) {
            salesArr.push({ x : dateArr[i], y: amountArr[i]})
          }

          setSalesData( salesArr);
        });
    }

    return (
        <>
            <Background>
                <p>일별 매출</p>
                <div style={{widh: "100%" , height: "100%"}}>
                    { sales_data.length && <ResponsiveLine
                        data={ [{"id": "sales", "color": "hsl(224, 80%, 70%)" , "data" : sales_data }] }
                        margin={{ top: 10, right: 50, bottom:50, left: 50 }}
                        xScale={{ type: 'point' }}

                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                            stacked: true,
                            reverse: false
                        }}

                        curve='monotoneX' 
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: 36,                          
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legendOffset: 0,
                          legendPosition: 'middle'
                      }}
                      colors={{ scheme: 'category10' }}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[]}
                        animate={true}
                    /> }
                </div>
            </Background>
        </>
    )

}

export default DailySales
