import React , { useContext, useEffect , useState }from "react";

import styled from "styled-components";
import { ResponsiveLine } from '@nivo/line' 
import axios from "axios";
import CategoryContext from "../../contexts/category";


const Background = styled.div ` 
    height: 200px;
    min-height: 0;
    min-width: 0 ; 
    
    border: solid 1px lightgray;
    box-shadow: 5px 5px 10px lightgray;
    border-radius: 15px;
    padding: 10px;
    min-width:300px;
`
const MonthlySale = (props) => {
    const { state  } = useContext( CategoryContext);
    const [ sales_data, setSalesData] = useState([]);
    useEffect( () => { getMonthlysSales(); },[ state ]);
  
    const getMonthlysSales = () => {
        let bodyData = {
            user_id : state.g_user_id,  
            shop_id : state.g_shop_id,
        }
        
        axios.post('/api/getMonthlySales', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
          var dateArr = []
          var amountArr = []
          var salesArr = []

          for (var i = 5; i >= 0 ; i--) {
            let date = new Date()
            date.setDate(1); // 31일일 경우 문제 생김. ..
            date.setMonth(date.getMonth() - i)

            let month = ('0' + (date.getMonth() + 1)).slice(-2)

            dateArr.push(`${month}`) // 
            amountArr.push(0)   // 0원으로 초기화 
          }


          for (var i = 0; i < data[0].length; i++) {
            var date = new Date(data[0][i].month)
    
            let month = ('0' + (date.getMonth() + 1)).slice(-2)
      
            var tmpDate = `${month}`
            var tmpDataArrIdx = dateArr.indexOf(tmpDate)
            
            amountArr[tmpDataArrIdx] = data[0][i].monthly_total
          }



          for (var i = 0; i < dateArr.length; i++) {
            salesArr.push({ x : dateArr[i], y : amountArr[i]})
          }

          setSalesData( salesArr);

        });
    }


    return (
        <>
            <Background>
                <p>월별 매출</p>
                <div style={{widh: "100%" , height: "100%"}}>
                    { sales_data.length && <ResponsiveLine
                        data={ [{"id": "sales", "color": "hsl(313, 70%, 50%)", "data" : sales_data }] }
                        margin={{ top: 10, right: 50, bottom:50, left: 50 }}
                        xScale={{ type: 'point' }}

                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                            stacked: true,
                            reverse: false
                        }}

                        curve='monotoneX' 
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: 36,
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: 0,
                            legendPosition: 'middle'
                        }}
                        colors={{ scheme: 'category10' }}
                        enableGridY={false}
                        pointSize={10}
                        pointColor={{ from: 'color' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from : 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        animate={true}
                        
                        
                    /> }

                </div>
            </Background>
        </>
    )

}

export default MonthlySale
