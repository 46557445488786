import React, { useContext , useState, useEffect, useRef} from "react";
import cookie  from 'react-cookies'
import { useLocation } from "react-router-dom";
import Select from "react-select"
import styled from "styled-components";
import CategoryContext from "../../contexts/category";
import axios from "axios";
//import zee_logo from '../../zee_photo.png';

const HeaderArea = styled.div `
    background-color: rgb(102, 118, 162);
    display: flex;
    width: 100%;
    height: 40px;
` 

const LogoArea = styled.div `
    width : 80px;
    height: 40px;
    display : flex;
    justify-content : center;
    align-items : center;   
   // background-color:rgb(132, 148, 192);
    font-size: 14px;
    font-weight:600;
    color: rgb(230,230,255);
    text-shadow: 4px 4px 2px rgb(132,148,192);
    line-height : 12px;
`

const GreetingArea = styled.div `
    min-width: 100px;
    height: 40px;  
    color : white;
    display : flex;
    justify-content : center;
    align-items : center;
    margin-right: auto;

`
const ShopIDArea = styled.div `
    width  : 150px;
    height: 40px; 
`

const CustomSelect = styled(Select).attrs({
    classNamePrefix: 'react-select',
})`   

.react-select__control {
    background-color: rgb(122, 138, 182);
    width: 150px;
    height: 38px;
    padding-right: 2px;
    border: none;
    border-radius: 18px;
    display: flex;
    text-align: center;
    align-items: center;
    cursor: Pointer;
    box-shadow: none;
  }
  .react-select__single-value {
    color: #ffffff; /* 텍스트 색상 지정 */
    font-size: 12px;
    font-weight: 600;
  }
  .react-select__menu {
    background-color: #ffffff;
    border-radius: 18px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    text-align: center;
    align-items: center; 
  }
  .react-select__option {
    display: flex;
    height: 38px;
    min-height: 38px;
    align-items: center;
    font-size: 12px;    
    font-weight: 600;   
    border-radius: 18px;  
    background-color: transparent; /* option 배경색 */
    color: black; /* option 텍스트 색상 */
    cursor: Pointer;   
  }
  .react-select__option--is-focused {
    border: 1px solid #afaeb7;
    color: black; /* hover 상태의 option 텍스트 색상 */
    cursor: Pointer;   
  }

  .react-select__option--is-selected {
    background-color:rgb(100, 110, 160); /* 클릭된 option 배경색 */
    color: white; /* 클릭된 option 텍스트 색상 */
  }
  
  .react-select__placeholder {
    color: white;
    font-size: 12px;    
    font-weight: 600;
  }
`;

const  Header = (props) => {
    const { state , actions } = useContext( CategoryContext);
    const [ shop_arr, setShopArr] = useState([])
    const selectInputRef = useRef(null)

    // eslint-disable-next-line
    useEffect(() => { getShopList() }, []);

    const pathName = useLocation().pathname;
    if ( pathName === '/login') return null;


    const getShopList = () => {
        let bodyData = {
            user_id : state.g_user_id
        }

        axios.post('/api/getShopList', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            var shop_arr = []

            shop_arr.push({ value: 'all', label: '모든 지점' })

            for(var i = 0; i < data.length; i++) {
                var shop = {
                    value : data[i].shop_id,
                    label : data[i].name
                };
                shop_arr.push(shop);
            }
            setShopArr(shop_arr)
        })
    }

    const setCurrShop = (e) => {
        cookie.save('shop_id',e.value, { path: '/',maxAge : 3600});
        actions.setShop(e.value);
    }

    return (
        <>
            <HeaderArea>
                <LogoArea>
                    Zee Photo<br/>Board
                </LogoArea>  

                <GreetingArea>
                    [ {state.g_user_name}  ] 
                </GreetingArea>
                <ShopIDArea>
                    <CustomSelect ref={selectInputRef} onChange={(e) => {  setCurrShop(e) }}                    
                            placeholder="지점 선택"
                            options= {shop_arr}                          
                            value = {shop_arr.filter(function (option) {
                                return option.value === state.g_shop_id
                            })}
                    /> 
                </ShopIDArea>                                 
                </HeaderArea>

        </>
    )
}

export default Header;
