import React, {useEffect, useState, useContext, useRef} from "react";
import styled from "styled-components"
import cookie  from 'react-cookies'
import { useNavigate } from 'react-router-dom';

import PaperStatus from "../Component/Widget/PaperStatus";
import Devices from "../Component/Widget/Devices";
import SetDevices from "../Component/Widget/SetDevices";

import Sidebar from "../Component/SideBar/SideBar";
import TopBar from "../Component/SideBar/TopBar";
import Header from "../Component/Header/Header";

import axios from "axios";
import CategoryContext from "../contexts/category";

const WidgetArea = styled.div `
    padding: 10px;
    display: grid;
    grid-template-columns: ${ (p) => `repeat( auto-fit, minmax( ${p.minWidth ||'350px'}, 1fr ) )`};
    grid-gap: 5px;
    background-color: white;
`

const DevicesArea = styled.div `
    padding: 10px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: ${ (p) => `repeat( auto-fit, minmax( ${p.minWidth ||'350px'}, 1fr ) )`};
    background-color: white;
    place-items: center;
`;

const DeviceManagenent = (props) => {
    const navigate = useNavigate(); 
    const { state  } = useContext( CategoryContext);    
    const [kioskList, setKioskList] = useState([])

    const authCheck = () => 
    {  
          let user_id  = cookie.load('user_id');  
        let user_name = cookie.load('name');
        let user_type = cookie.load('type');
        let shop_id   = cookie.load('shop_id');  
        if ( user_id  === undefined )
        {
            navigate('/login');
        }
        else
        {
            cookie.save('user_id',user_id , { path: '/',maxAge : 3600 });
            cookie.save('name',user_name , { path: '/',maxAge : 3600});
            cookie.save('type',user_type , { path: '/',maxAge : 3600});
            cookie.save('shop_id',shop_id, { path: '/',maxAge : 3600});
        }
    }

    useEffect( () => { authCheck(); },[]);

    useEffect(() => { getKioskStatus()}, [state]);

    const getKioskStatus = () => {
        let bodyData = {
            user_id : state.g_user_id,  
            shop_id : state.g_shop_id,
        }
    
        axios.post('/api/getKioskStatus', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            setKioskList(data)
        })
    }

    return (
        <>
        <Header/>
        <TopBar/>
        <div className="main_container">        
            <WidgetArea>
                <Devices/>
                <PaperStatus/>
            </WidgetArea>

            <DevicesArea>
                {kioskList.map((item, key) => (

                    <SetDevices
                        key                 = {key}
                        net_status          = {item.net_status }
                        device_name         = {item.kiosk_name}
                        deviceNumber        ={item.kiosk_id}                        
                        camera_status       ={item.camera_status === "O" ? "정상":"오류" }     
                        printer_status      ={item.printer_status === "O" ? "정상":"오류"}
                        bill_status         ={item.bill_status === "O" ? "정상" : "오류"}
                        error_display       ={item.camera_status === "O" && item.printer_status === "O" &&  item.bill_status === "O" ? "정상":"오류"}                         
                        paper_remain        ={item.paper_remain}
                        update_dt           ={item.update_dt}                        
                        recentPaymentAmount ={item.amount}
                        recentPaymentDay    ={item.recentPaymentDay}
                    />

                ))}

            </DevicesArea>

            </div>
        </>
    )
}

export default DeviceManagenent