import React , { useContext, useEffect , useState }from "react";

import styled from "styled-components";
import axios from "axios";
import CategoryContext from "../../contexts/category";
import Pagination from "react-js-pagination"

const Background = styled.div `
    border: solid 1px lightgray;
    box-shadow: 5px 5px 10px lightgray;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction:column;
    min-width:300px;
`

const ProgressBarContainer = styled.div `
    width: 100%;
    height: 12px;
    border-radius: 7px;  
    background-color: #CCDDEE;    
`

const WidgetBox = styled.div `
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px  ;
`

const PageNationGap = styled.div `
    justify-content: center;
    flex: 1;
`

const PageNationBox = styled.div `
    height: 60px;
    justify-content: center;
`


const PaperStatus = (props) => {
    const maxPaperCnt = 700
  
    const { state  } = useContext( CategoryContext);
    const [ paper_data, setPaperData ] = useState([]);
    const [ paper_page, setPaperPage ] = useState([]);
    const [page, setPage] = useState(1);

    const handlePageChange = (newpage) => {
      setPage(newpage);      
      setPaperPage(paper_data.slice( (newpage-1)*4,(newpage-1)*4+4) );  
    }

    useEffect( () => { getPaperStatus(); },[ state]);

    const getPaperStatus = () => {
        let bodyData = {
            user_id : state.g_user_id,  
            shop_id : state.g_shop_id,
        }
        
        axios.post('/api/getLeftOverPaper', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            setPaperData(data[0]);
            setPage(1);            
            setPaperPage(data[0].slice(0,4) );

        });
    }



    return (

        <>
        <Background>
            <WidgetBox>
        {paper_page && paper_page.map((device, index) => (          
           <div key={index}  style={{ margin: '0px 5px'}}>
                <p>{device.name} </p>
                <p>{device.paper_remain} / {maxPaperCnt}</p>
                <ProgressBarContainer style={{backgroundColor: device.paper_remain < 100  ?  "#EEC0BB" : "#CCDDEE"  }} >
                    <div  style={{                        
                        backgroundColor: '#556688',
                        height: '12px',
                        width: `${device.paper_remain / maxPaperCnt * 100}%`,
                        borderRadius: '6px',
                        border: 'none',
                        backgroundColor: device.paper_remain < 100  ?  "#AA4433" : "#556688" , 

                    }}/>
                </ProgressBarContainer>
            </div>
        ))}

        </WidgetBox>
        <PageNationGap/>

             <PageNationBox>
            {page && <Pagination
                activePage={page}
                itemsCountPerPage={4} 
                totalItemsCount={paper_data.length} 
                pageRangeDisplayed={5} 
                prevPageText={"‹"} 
                nextPageText={"›"} 
                onChange={handlePageChange} 
                />  
            }
            </PageNationBox>
     

         </Background>  
    </>

    )

}

export default PaperStatus
