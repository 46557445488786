import React from "react";
import styled from "styled-components";

const IconBox = styled.div `
display: flex;
flex-direction: column;
justify-content: center; 
margin-top: 5px;
margin-left: 10px;
margin-right: 10px;
margin-bottom: 0px;
border-radius: 10px;
width: 50px;
height: 50px;
font-size: 10px;

`
const InnerIconBox = styled.div `
display: flex;
justify-content: center;
`
const TextBox = styled.div `
display: flex;
flex-direction: column;
justify-content: center;
text-align: center;
height: 20px;

font-size: 10px;
color: rgb(102, 118, 162);
`

function TopbarItem({ menu, isActive ,icon}) {

  return (
    <>
    <IconBox style={{ backgroundColor: isActive===true? ' rgb(70, 90, 130)':'rgb(102, 118, 162) '}} >
      <InnerIconBox>
      <menu.icon  size={25} color={ isActive===true?'cyan':'white'} title={menu.name}/>        
      </InnerIconBox>
    </IconBox>
    <TextBox>{menu.name}</TextBox>    
    </>   
  ) 
}

export default TopbarItem;