import React , { useContext, useEffect , useState }from "react";

import styled from "styled-components";
import axios from "axios";
import CategoryContext from "../../contexts/category";

const Background = styled.div `
    border: solid 1px lightgray;
    box-shadow: 5px 5px 10px lightgray;
    border-radius: 15px;
    padding: 10px;
    min-width:300px;
`

const DeviceBox = styled.div `
    display: flex;
    justify-content: space-between;
    align-items : center;
    vertical-align: middle;
    padding-left:  20px;
    background-color: #EEEEEE;
    box-shadow: 3px 3px 3px lightgray;    
    border-radius: 20px;   
`

const WidgetBox = styled.div `
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 766px ) {
        grid-template-columns: 1fr ;
    }
        
    grid-gap: 5px;
`

 const ButtonBox = styled.div `
    display: grid;
    grid-template-columns: 1fr 1fr;
    float: right;
 `

 const TextBubble = styled.div `
    width: 30px;
    height: 30px;
    font-size: 12px;

    border-radius: 15px ;
    /* border: solid 1px black; */

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 2px;
`
const DevicesStatus = (props) => {

    const { state  } = useContext( CategoryContext);
    const [ kioskstaus_array,     setKioskStatusArray ] = useState([])

    useEffect( () => { getDevicesStatus(); },[ state]);
 
    const getDevicesStatus = () => {
        let bodyData = {
            user_id : state.g_user_id,  
            shop_id : state.g_shop_id,
        }
        
        axios.post('/api/getDeviceStatus', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            setKioskStatusArray(data[0]);            
        });
    }

    const renderInnerComponent = () => {
        return kioskstaus_array.map((kiosk, i) => (
            <DeviceBox key = {i}>
                <p style={{float: "left",fontSize:"12px"}}> {kiosk.kiosk_name}</p>
                <ButtonBox>
                    <TextBubble style={ kiosk.net_status === "ON"  ?  {backgroundColor: "lightgreen"} : {backgroundColor: "lightgray"} }>
                        <p>{kiosk.net_status}</p>
                    </TextBubble>
                    <TextBubble style={ kiosk.camera_status === "O"  ?  {backgroundColor: "lightgreen"} : {backgroundColor: "lightgray"} } >
                        <p>{kiosk.camera_status === "O" ? "정상" : "오류"}</p>
                    </TextBubble>
                </ButtonBox>
            </DeviceBox>
        ))
    }

    return (
        <>
            <Background>
                <p>장비현황</p>
                <WidgetBox>
                    {renderInnerComponent()}
                </WidgetBox>
            </Background>
        </>
    )
}

export default DevicesStatus
