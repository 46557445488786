import React from "react";

import styled from "styled-components";

const Settings = (props) => {
    return (
        <>
            <h1>Settings</h1>
            <p>page</p>
        </>
    )
};

export default Settings;