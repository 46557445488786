import React from "react";
import './App.css'
import Router from "./Component/Router";
import { CategoryProvider } from "./contexts/category";

function App() {
    return (
        <CategoryProvider>
        <Router/>
        </CategoryProvider>
    )
}

export default App;
