import React from "react";

import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import TopbarItem from "./TopbarItem";

import { House,  Calculator, Gear, BoxArrowRight, GraphUp } from 'react-bootstrap-icons';

const TopbarArea = styled.div `
  hight: 60px;
  display: flex;
  flex-direction: low;
  left: 0;
  text-align: center ;
  width: 100%;
  align-items: center;
  justify-content: top; 
  background-color: rgb(212, 215, 228);
` 

function TopBar() {

  // URL의 path값을 받아올 수 있다.
  const pathName = useLocation().pathname;
  if ( pathName === '/login') return null;

  const menus = [
    { name: "HOME", path: "/" , icon: House },
    { name: "매출현황", path: "/sales_status" , icon: GraphUp},
   // { name: "매출집계", path: "/sales_management" },
    { name: "장치관리", path: "/device_management" , icon: Gear},
    //{ name: "설정", path: "/settings" },
    { name: "LOGOUT", path: "/login" , icon: BoxArrowRight},
  ];

  return (
    <TopbarArea>
      {menus.map((menu, index) => {
        return (
          <Link to={menu.path} key={index}>
            <TopbarItem
              menu={menu}
              isActive={pathName === menu.path ? true : false}
            />            
          </Link>
        );
      })}
    </TopbarArea>
  );
}

export default TopBar;
