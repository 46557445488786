import React , { useContext, useEffect , useState }from "react";

import styled from "styled-components";
import axios from "axios";
import CategoryContext from "../../contexts/category";

const Background = styled.div `
border: solid 1px lightgray;
box-shadow: 5px 5px 10px lightgray;
border-radius: 15px;
padding: 10px;
min-width:300px;
`

const MoneyArea = styled.div `
    width: 100%
`

const BottomBackground = styled.div `
border: solid 1px lightgray;
background-color:rgb(102, 118, 162);
color : white;
box-shadow: 5px 5px 10px lightgray;
border-radius: 15px;
padding: 10px;  
margin-top: 20px;
margin-bottom: 10px;
`

const TodaysSale = (props) => {

    const { state  } = useContext( CategoryContext);
    const [ yesterdays_income, setYesterdaysIncome ] = useState(0)
    const [ todays_income,setTodaysIncome ] = useState(0) 
    const [ card_income_sum , setCardIncomeSum ] = useState(0);
    const [ cash_income_sum , setCashIncomeSum  ] = useState(0); 

    useEffect( () => { getTodaysSales(); }, [state]);


    const getDate = () => {
        let date = new Date()

        let year = date.getFullYear()
        let month = String(date.getMonth() + 1).padStart(2,'0')
        let todayDate = String(date.getDate()).padStart(2, '0')    
     //   const week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
     //   let dayweek = week[date.getDay()]

        return year + '.' + month  + '.' + todayDate // + ' ' + dayweek
    }

    const getTodaysSales = () => {
        let bodyData = {
            user_id : state.g_user_id,  
            shop_id : state.g_shop_id,
        }
        
        axios.post('/api/getTodaysSales', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {

          setTodaysIncome ( data[0][0].todays)
          setYesterdaysIncome ( data[1][0].yesterdays )
          setCardIncomeSum ( data[2][0].card_sum )
          setCashIncomeSum ( data[3][0].cash_sum )

        });
    }


        return (
            <>
                <Background>
                    <p>오늘매출 ( {getDate()} )</p>
                    <MoneyArea>
                        <h1 > {todays_income.toLocaleString()} 원</h1>
                        <hr/>
                        <p>어제보다 { (todays_income - yesterdays_income).toLocaleString()} 원 </p>
                    </MoneyArea>
                    
                    <BottomBackground>
                        <p>카드결제 : {card_income_sum.toLocaleString()} 원 </p>
                        <p>현금결제 : {cash_income_sum.toLocaleString()} 원 </p>
                    </BottomBackground>
                </Background>
            </>
        )

}

export default TodaysSale
