import React, {useEffect, useState, useContext, useRef} from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ko } from "date-fns/esm/locale"
import Pagination from "react-js-pagination"
import { ExportToCsv } from "export-to-csv";
import Swal from 'sweetalert2';
import cookie from 'react-cookies'
import axios from "axios";

import AllofCashAmount from "../Component/Widget/AllofCashAmount";
import AllofCardAmount from "../Component/Widget/AllofCardAmount";
import MonthlySales from "../Component/Widget/MonthlySales";

import Sidebar from "../Component/SideBar/SideBar";
import Header from "../Component/Header/Header";
import CategoryContext from "../contexts/category";

const WidgetArea = styled.div `
    background-color: white;

    justify-content : flex;

    display: flex;
    flex-flow: row nowrap;

    width: 100%;
    height: 30%;
`
const ControlboxArea = styled.div `
    background-color: white;

    display: flex;
    flex-flow: row nowrap;
    justify-content : flex-end;

    width: 100%;
    height: 10%;
`

const TableBoxArea = styled.div `
    background-color: white;

    display: flex;
    flex-direction: row;
`

const CustomSelect = styled(Select) `
    width: 150px;
`



const SalesManagenent = (props) => {
    const navigate = useNavigate(); 
    const { state  } = useContext( CategoryContext);
    var   page = 1 ; 

    const [kiosk_arr, setKioskArr] = useState([])
    const [kiosk_id, setKioskId] = useState("")
    const [classifyDateArr, setClassifyDateArr] = useState([])
    const [classifyDate, setClassifyDate] = useState([])
    const [numOfColumnArr, setNumOfColumnArr] = useState([])
    const [numOfColumn, setNumOfColumn] = useState([])
    const [salesManagementData, setSalesManagementData] = useState([])
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const selectInputRef = useRef(null)

    const authCheck = () => 
    {  
        console.log("authCheck()");
        let user_id  = cookie.load('user_id');  
        let user_name = cookie.load('name');
        let user_type = cookie.load('type');
        let shop_id   = cookie.load('shop_id');  
        if ( user_id  === undefined )
        {
            navigate('/login');
        }
        else
        {
            cookie.save('user_id',user_id , { path: '/',maxAge : 3600 });
            cookie.save('name',user_name , { path: '/',maxAge : 3600});
            cookie.save('type',user_type , { path: '/',maxAge : 3600});
            cookie.save('shop_id',shop_id, { path: '/',maxAge : 3600});
        }
    }

    useEffect( () => { authCheck(); },[]);


    const getKioskList = () => {
        let bodyData = {
            shop_id : "부평점"
        }

        axios.post('/api/getSalesStatusKioskList', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            var kiosk_arr = []

            kiosk_arr.push({ value: 'all', label: '모든 키오스크' })

            for(var i = 0; i < data.length; i++) {
                var kiosk = {
                    value : data[i].kiosk_id,
                    label : data[i].kiosk_name
                };
                kiosk_arr.push(kiosk)
            }

            setKioskArr(kiosk_arr)
        })
    }

    const getSalesStatus = () => {
        let bodyData = {
            user_id : state.g_user_id,  
            shop_id : state.g_shop_id,
            start_date : startDate.toISOString().slice(0, 10),
            end_date : endDate.toISOString().slice(0, 10),
            limit_num : numOfColumn,
            page_num : page-1,
            kiosk_id : kiosk_id,
            classyfy_date : classifyDate
        }

        axios.post('/api/getSalesManagement', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            setSalesManagementData(data[0])
        })
    }
    
    const insertDropdownListArr = () => {
        var classifyDateArr = []
        var numOfColumn = []

        classifyDateArr.push(
            {value : "daily", label : "일별"},
            {value : "monthly", label : "월별"},
        )

        setClassifyDateArr(classifyDateArr)
        
        numOfColumn.push(
            {value : 15, label : "Show 15"},
            {value : 30, label : "Show 30"},
            {value : 50, label : "Show 50"},
            {value : 100, label : "Show 100"}
        )

        setNumOfColumnArr(numOfColumn)
    }

    const ExportToExcel = () => {
        console.log("엑셀 버튼 눌림")
    }

    const loadSalesData = () => {
        console.log("조회 버튼 눌림")
        getSalesStatus()
    }

    return (
        <>
        <Header/>
        <Sidebar/>
        <div className="main_container"> 
            <WidgetArea>
                <AllofCashAmount/>
                <AllofCardAmount/>
                <MonthlySales/>
            </WidgetArea>

            <ControlboxArea>
                <CustomSelect 
                    ref={selectInputRef} onChange={(e) => {
                        setClassifyDate(e.value)
                    }}
                    options={classifyDateArr}
                    placeholder="분류"
                    value = {classifyDateArr.filter(function (option) {
                        return option.value === classifyDate
                    })}
                />
                <CustomSelect ref={selectInputRef} onChange={(e) => {
                        setKioskId(e.value)
                    }}
                    options={kiosk_arr}
                    placeholder="키오스크 선택"
                    value = {kiosk_arr.filter(function (option) {
                        return option.value === kiosk_id
                    })}
                />
                <CustomSelect ref={selectInputRef} onChange={(e) => {
                        setNumOfColumn(e.value)
                    }}
                    options={numOfColumnArr}
                    placeholder="데이터 갯수"
                    value = {numOfColumnArr.filter(function (option) {
                        return option.value === numOfColumn
                    })}
                />
                <DatePicker
                    locale = {ko}
                    classname = "input-datepicker"
                    closeOnScroll = {true}
                    placeholderText = "처음 날짜"
                    selected = {startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="yyyy-MM-dd"
                />
                <DatePicker
                    locale = {ko}
                    classname = "input-datepicker"
                    closeOnScroll = {true}
                    placeholderText = "마지막 날짜"
                    selected = {endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="yyyy-MM-dd"
                />
                {/* 검색어 입력 */}
                <button onClick={ExportToExcel}>EXCEL</button>
                <button onClick={loadSalesData}>조회</button>
            </ControlboxArea>

            <TableBoxArea>
                <table>
                    <thead>
                        <tr>
                            <th>가맹점명</th>
                            <th>장치명</th>
                            <th>용지</th>
                            <th>템플릿</th>
                            <th>필터</th>
                            <th>인화장수</th>
                            <th>금액</th>
                            <th>결제방법</th>
                            <th>매출일시</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesManagementData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.shop_id}</td>
                                <td>{item.name}</td>
                                <td>{item.paper_type}</td>
                                <td>{item.template_type}</td>
                                <td>{item.filter_type}</td>
                                <td>{item.printer_count}</td>
                                <td>{item.amount}</td>
                                <td>{item.payment_type}</td>
                                {classifyDate === "monthly" ? (
                                    <td>{item.year}년 {item.month}월</td>
                                ) : (
                                    <td>{item.tr_datetime}</td>
                                )}
                                <td>{item.tr_error}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TableBoxArea>

            </div>
        </>
    )
}

export default SalesManagenent
 