import React , { useState , useRef } from "react";
import Modal from 'react-modal';

import Select from "react-select"
import styled from "styled-components";
import axios from "axios";
import Swal from 'sweetalert2';

const Background = styled.div `
    background-color: white;
    border: solid 1px lightgray;
    box-shadow: 5px 5px 10px lightgray; 
    border-radius: 15px;
    min-width: 300px;
    height: 300px;
`
const TableArea = styled.div `
    padding: 10px;
`

const TitleBar = styled.div `
    background-color: rgb(102, 118, 162);
    height: 20px;

    display: flex;
    justify-content: space-between; 
    align-items : center;
    border-radius: 15px 15px 0px 0px;
 
    padding: 10px;

    color: white;
`

const BubbleBox = styled.div `
    display: grid;
    grid-template-columns: 1fr 1fr;
    float: right;
`
const ButtonBox = styled.div `
    display: flex;
    justify-content: center; 
`

const TextBubble = styled.div `
    width: 50px;
    height:20px;

    border-radius: 10px;
  //  border: solid 1px white;
    background-color: gray;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
`

const RecentOfMoneyArea = styled.div `
    background-color: rgb(238, 239, 245);
    padding: 20px;
`

const Table = styled.table `
    width: 100%;
    table-layout: fixed;
`

const Fixedtd = styled.td `
    font-size: 14px;
    min-width: 100px;
`

const PointButton = styled.button `
display: flex;
align-items: center;
justify-content: center;
width: 100px;
height: 30px;
color: white;
border-radius: 5px;
margin: 5px;
border: none;
background-color: rgb(102, 162, 118);    
&:hover  {     background-color: rgb(102, 200, 140); cursor:pointer;  };
&:active {     background-color: rgb(44, 100, 60);  };
`
const ControlButton = styled.button `
display: flex;
align-items: center;
justify-content: center;
width: 100px;
height: 30px;
color: white;
border-radius: 5px;
margin: 5px;
border: none;
background-color: rgb(112, 128, 144);    
&:hover  {     background-color: rgb(142, 168, 184); cursor:pointer; };
&:active {     background-color: rgb(82, 98, 114);  };
`

const CustomSelect = styled(Select) `
    height: 40px;
    width: 200px;
    text-align: center; 
`

const  SetDevices = (props) => {

    const point_arr = 
    [
    { value:   4000, label : "4,000 point"},
    { value:   5000, label : "5,000 point"},
    { value:   8000, label : "8,000 point"},
    { value:  10000, label : "10,000 point"},
    { value:  12000, label : "12,000 point"},
    { value:  15000, label : "15,000 point"}  ];



     const [pointDialog, setPointDialog] = useState(false);
     const [controlDialog, setControlDialog] = useState(false);
     const [numOfPoint, setNumOfPoint] = useState(5000);
     const selectInputRef = useRef(null)

     const sweetalert = (title, contents, icon, confirmButtonText) => {
        Swal.fire({
            title: title,
            text: contents,
            icon: icon,
            confirmButtonText: confirmButtonText
          })
    }

    const DrawPoint = () =>
    {
        setKioskPoint();
        setPointDialog(false);
    }

    const ResetCamera = () => 
    {
        setKioskControl('C');
        setControlDialog(false);
    }

    const ResetBill = () => 
    {
        setKioskControl('B');        
        setControlDialog(false);
    }
    
    const setKioskControl = ( control ) => {
        let bodyData = {
            control_val : control , 
            kiosk_id : props.deviceNumber,  
        }       

        axios.post('/api/setKioskControl', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            if ( data.affectedRows > 0 )
            {
                if ( control === 'C')
                    sweetalert("적용 되었습니다.", '카메라장치 리셋' , 'success','확인' );
                else
                    sweetalert("적용 되었습니다.", '지폐장치 리셋' , 'success','확인' );

            }

        })       
      

    }

    const setKioskPoint = () =>  {
        let bodyData = {
            coupon_val : numOfPoint , 
            kiosk_id : props.deviceNumber,  
        }       

        axios.post('/api/setKioskPoint', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            if ( data.affectedRows > 0 )
                sweetalert("point 가 지급되었습니다.", numOfPoint.toLocaleString() , 'success','확인' );
        })       

    }


    return (
        <>
            <Background>

                <TitleBar>
                  {props.device_name}
                    <BubbleBox>
                        <TextBubble style={{ backgroundColor : props.net_status==='ON'? 'rgb(102, 162, 118)':'darkgray'}}>
                          {props.net_status}
                        </TextBubble>
                        <TextBubble style={{ backgroundColor : props.error_display==='오류'? 'rgb(162, 80, 80)':'rgb(102, 162, 118)'}}>
                          {props.error_display}
                        </TextBubble>
                    </BubbleBox>
                </TitleBar>

                <TableArea>
                    <Table>
                        <tbody>
                        <tr>
                            <Fixedtd>장치 번호</Fixedtd>
                            <td >{props.deviceNumber}</td>
                        </tr>
                        <tr>
                            <Fixedtd>프린터</Fixedtd>
                            <td style={{ color: props.printer_status==='오류'? 'red':'black'}} >{props.printer_status}</td>
                        </tr>
                        <tr>
                            <Fixedtd>카메라</Fixedtd>
                            <td style={{ color: props.camera_status==='오류'? 'red':'black'}} >{props.camera_status}</td>
                        </tr>
                        <tr>
                            <Fixedtd>지폐장치</Fixedtd>
                            <td style={{ color: props.bill_status==='오류'? 'red':'black'}} >{props.bill_status}</td>
                        </tr>
                        <tr>
                            <Fixedtd>용지잔여장수</Fixedtd>
                            <td style={{ color: props.paper_remain < 6 ? 'red':'black'}} >{props.paper_remain}</td>
                        </tr>
                        <tr>
                            <Fixedtd>최근서버 접속시간</Fixedtd>
                            <td>{props.update_dt}</td>
                        </tr>
                        </tbody>
                    </Table>
                </TableArea>
{/*
                <RecentOfMoneyArea>
                    <Table>
                        <tr>
                            <Fixedtd>최근 입금액</Fixedtd>
                            <td>{props.recentPaymentAmount}</td>
                        </tr>
                        <tr>
                            <Fixedtd>최근 입금일</Fixedtd>
                            <td>{props.recentPaymentDay}</td>
                        </tr>
                    </Table>
                </RecentOfMoneyArea>
*/}

                <ButtonBox>
                    <PointButton onClick={ ()=> { setPointDialog(true)} } >포인트 지급</PointButton>
                    <ControlButton onClick={ () => {setControlDialog(true)} }>장치 관리 </ControlButton>
                </ButtonBox>
            </Background>

            <Modal   
                style={{
                    content: { position: 'absolute',  width: '400px',  top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    border: '1px solid #ccc', background: 'white' ,borderRadius : '15px',padding: 0  }
                }}
                isOpen = {pointDialog}
                ariaHideApp={false}
                >

                <TitleBar> 포인트 지급 </TitleBar>
                <div style={{  paddingTop:'30px', width:'100%', display:'flex', justifyContent: 'center'}}>
                    {props.device_name} [{props.deviceNumber}] 에 <br/> {numOfPoint.toLocaleString()}포인트를 지급하시겠습니까? 
                 </div>

                 <div style={{ paddingTop:'30px', width:'100%', display:'flex', justifyContent: 'center'}}>                
                    <CustomSelect  ref={selectInputRef} onChange={(e) => { console.log(e.value); setNumOfPoint(e.value)}}

                    options={point_arr}
                    placeholder="지급할 포인트 선택"
                    value = {point_arr.filter( (option) => {  return option.value === numOfPoint })}
                    >
                    </CustomSelect>
                </div>          
                
                <div style={{paddingTop: '50px' ,paddingBottom: '50px' , width:'100%', display:'flex', justifyContent: 'center'}}>   
                    <PointButton onClick={  DrawPoint }> 지급</PointButton>    
                    <ControlButton onClick={ ()=> { setPointDialog(false)}}> 닫기 </ControlButton>    
                </div>


            </Modal>

            <Modal
                style={{
                    content: { position: 'absolute',  width: '400px', height: '250px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    border: '1px solid #ccc', background: 'white' ,borderRadius : '15px',padding: 0 }
                }}            
               isOpen = {controlDialog}    
               ariaHideApp={false}        
            >   
                <TitleBar> 장치 관리  </TitleBar>
                <div style={{padding: '0' , width:'100%', display:'flex', justifyContent: 'center', paddingTop:'20px'}}>
                    {props.device_name} [{props.deviceNumber}] 장치를 제어합니다.   
                 </div>

                 <div style={{padding: '0' , width:'100%', display:'flex', justifyContent: 'center', paddingTop:'20px'}}>                
                 <PointButton onClick={  ResetCamera  }> 카메라 리셋</PointButton>    
                    <PointButton onClick={  ResetBill }> 지폐 리셋 </PointButton>    
                </div>          
                
                <div style={{padding: 0 , width:'100%', display:'flex', justifyContent: 'center', paddingTop:'20px'}}>   
                    <ControlButton onClick={ ()=> { setControlDialog(false)}}> 닫기 </ControlButton>    
                </div>

            </Modal>


        </>
    )

}

export default SetDevices