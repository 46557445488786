import React , { useContext, useEffect , useState }from "react";

import styled from "styled-components";
import axios from "axios";
import CategoryContext from "../../contexts/category";

const Background = styled.div `  
    border: solid 1px lightgray;
    box-shadow: 5px 5px 10px lightgray;
    border-radius: 15px;
    padding: 10px;
    min-width:300px;
`

const BottomBackground = styled.div `
    border: solid 1px lightgray;
    background-color:rgb(102, 118, 162);
    color : white;
    box-shadow: 5px 5px 10px lightgray;
    border-radius: 15px;
    padding: 10px;  
    margin-top: 20px;
    margin-bottom: 10px;
`
const AllofSales = (props) => {

    const { state  } = useContext( CategoryContext);
    const [ allof_incom, setAllofIncome ] = useState(0)
    const [ todays_income,setTodaysIncome ] = useState(0) 
    const [ card_income_sum , setCardIncomeSum ] = useState(0);
    const [ cash_income_sum , setCashIncomeSum  ] = useState(0); 

    useEffect( () => { getAllofSales(); },[ state]);

    const getDate = () => {
        let date = new Date()

        let year = date.getFullYear()
        let month = String(date.getMonth() + 1).padStart(2,'0')
        let todayDate = String(date.getDate()).padStart(2, '0')    
     //   const week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
     //   let dayweek = week[date.getDay()]

        return year + '.' + month  + '.' + todayDate  // + ' ' + dayweek
    }

    const getAllofSales = () => {
        let bodyData = {
            user_id : state.g_user_id,  
            shop_id : state.g_shop_id,
            start_date : "2023-01-01"
        }
        
        axios.post('/api/getAllofSales', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
             setAllofIncome ( data[0][0].allof_amount )
             setTodaysIncome ( data[1][0].todays_amount )
             setCardIncomeSum ( data[2][0].card_amount )
             setCashIncomeSum ( data[3][0].cash_amount )

        });
    }


    return (
        <>
            <Background>
                <p>전체매출  ( ~ { getDate() } )</p>
                <h1> { allof_incom.toLocaleString()} 원</h1>
                <hr/>
                <p>오늘매출 :  { todays_income.toLocaleString()} 원</p>
                <p></p>

                <BottomBackground>
                    <p>카드결제 : { card_income_sum.toLocaleString()} 원</p>
                    <p>현금결제 : { cash_income_sum.toLocaleString()} 원</p>
                </BottomBackground>
            </Background>
        </>
    )

}

export default AllofSales

        
