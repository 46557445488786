import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import cookie  from 'react-cookies'
import { useNavigate } from 'react-router-dom';
import Sidebar from "../Component/SideBar/SideBar";
import TopBar from "../Component/SideBar/TopBar";
import Header from "../Component/Header/Header";
import CategoryContext  from "../contexts/category";

import TodaysSale from "../Component/Widget/TodaysSale";
import AllofSales from "../Component/Widget/AllofSales";
import PaperStatus from "../Component/Widget/PaperStatus";
import DailySales from "../Component/Widget/DailySales";
import MonthlySales from "../Component/Widget/MonthlySales";
import Devices from "../Component/Widget/Devices";
import DeviceStatus from "../Component/Widget/DeviceStatus";

const FisrtWidgetLine = styled.div `
padding: 10px;
display: grid;
grid-template-columns: ${ (p) => `repeat( auto-fit, minmax( ${p.minWidth ||'300px'}, 1fr ) )`};
grid-gap: 5px;
background-color: white;
`

const SecondWidgetLine = styled.div `
padding: 10px;
display: grid;
grid-template-columns: ${ (p) => `repeat( auto-fit, minmax( ${p.minWidth ||'300px'}, 1fr ) )`};
grid-gap: 5px;
background-color: white;
`

const ThirdWidgetLine = styled.div `
padding: 10px;
display: grid;
grid-template-columns: ${ (p) => `repeat( auto-fit, minmax( ${p.minWidth ||'300px'}, 1fr ) )`};
grid-gap: 5px;
background-color: white;
`


const Home = (props) => {
    const navigate = useNavigate(); 

    const { state , actions } = useContext( CategoryContext);

    const authCheck = () => 
    {  
        let user_id  = cookie.load('user_id');  
        let user_name = cookie.load('name');
        let user_type = cookie.load('type');
        let shop_id   = cookie.load('shop_id');  
        if ( user_id  === undefined )
        {
            navigate('/login');
        }
        else
        {
            cookie.save('user_id',user_id , { path: '/',maxAge : 3600 });
            cookie.save('name',user_name , { path: '/',maxAge : 3600});
            cookie.save('type',user_type , { path: '/',maxAge : 3600});
            cookie.save('shop_id',shop_id, { path: '/',maxAge : 3600});
        }
    }

    useEffect( () => { authCheck(); },[]);


    return (
        <>
        <Header/>
        <TopBar/>
        <div className="main_container">
        <FisrtWidgetLine>
                <TodaysSale/>
                <AllofSales/>
                <PaperStatus/>
            </FisrtWidgetLine>

            <SecondWidgetLine>
                <DailySales/>
                <MonthlySales/>
            </SecondWidgetLine>    
            <ThirdWidgetLine>
                <Devices/>
                <DeviceStatus/>
            </ThirdWidgetLine>            
            </div>
        </>
    )
};
export default Home;
