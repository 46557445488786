import React , { useContext, useEffect , useState }from "react";

import styled from "styled-components";
import axios from "axios";
import CategoryContext from "../../contexts/category";

const Background = styled.div `
border: solid 1px lightgray;
box-shadow: 5px 5px 10px lightgray;
border-radius: 15px;
padding: 10px;
min-width:300px;
`

const WidgetBox = styled.div `
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
`
const Widget = styled.div `
    display: flex;
    height: 60px;
    align-items: center;    
    text-align: center;
    border-radius: 30px;
    box-shadow: 3px 3px 4px ${(props) => props.shColor ? props.shColor : 'gray'} ; 
    background-color: ${(props) => props.bgColor ? props.bgColor : 'white'};
`

const TextBubble = styled.div `
    height: 60px;
    width:  60px;    
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: ${(props) => props.bgColor }    
`
const NumberBox = styled.div `
    flex: 1 1 0 ;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
`


const Devices = (props) => {

    const { state  } = useContext( CategoryContext);
    const [ kiosk_cnt,     setKioskCnt    ] = useState(0)
    const [ not_errored,   setNotErrored  ] = useState(0) 
    const [ errored ,      setErrored     ] = useState(0);
    const [ available ,    setAvailable   ] = useState(0); 
    const [ unavailable ,  setUnavailable ] = useState(0); 

    useEffect( () => { getDevices(); },[ state]);

    const getDevices = () => {
        let bodyData = {
            user_id : state.g_user_id,  
            shop_id : state.g_shop_id,
        }
        
        axios.post('/api/getDevices', JSON.stringify(bodyData), {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(({ data }) => {
            setKioskCnt   (  data[0][0].kiosk_cnt    )           
            setNotErrored (  data[1][0].not_errored  )
            setErrored    (  data[1][0].errored  )
            setAvailable  (  data[1][0].available  )
            setUnavailable(  data[1][0].unavailable  )            
        });
    }
    return (
        <>
            <Background>
                    <p>찰영장비 {kiosk_cnt}대</p>
                    <WidgetBox>
                        <Widget bgColor='#BBFFBB' shColor='#AADDAA'>
                            <TextBubble bgColor='#009900' >
                                <p>ON</p>
                            </TextBubble>
                            <NumberBox >
                                {available}
                            </NumberBox>
                        </Widget>

                        <Widget bgColor='#CCCCCC' shColor='#AAAAAA'>
                            <TextBubble bgColor='#999999'>
                                <p>OFF</p>
                            </TextBubble>
                            <NumberBox >
                            {unavailable}
                            </NumberBox>
                            
                        </Widget>
                        <Widget bgColor='#BBBBFF' shColor='#AAAACC'>
                            <TextBubble bgColor='#000099' >
                                <p>정상</p>
                            </TextBubble>
                            <NumberBox >
                            {not_errored}
                            </NumberBox>                            
                        </Widget>

                        <Widget bgColor='#FFBBBB' shColor='#CCAAAA'>
                            <TextBubble bgColor='#990000'>
                                <p>오류</p>
                            </TextBubble>
                            <NumberBox >
                            {errored}
                            </NumberBox>                            
                        </Widget>
                    </WidgetBox>
            </Background>
        </>
    )
/*
    return (
        <>
            <Background>
                    <p>찰영장비 {kiosk_cnt}대</p>
                    <WidgetBox>
                        <Widget style={ {backgroundImage: "linear-gradient(30deg,#99ff99,white"}  }  >
                            <TextBubble style={ {backgroundImage: "linear-gradient(30deg,DarkGreen,LawnGreen"} } >
                                <p>ON</p>
                            </TextBubble>
                            <NumberBox >
                                {available}
                            </NumberBox>
                        </Widget>

                        <Widget style={  {backgroundImage: "linear-gradient(30deg,#999999,white"}  }>
                            <TextBubble style={ {backgroundImage: "linear-gradient(30deg,Black,lightgray"} }>
                                <p>OFF</p>
                            </TextBubble>
                            <NumberBox >
                            {unavailable}
                            </NumberBox>

                            
                        </Widget>

                        <Widget style={   {backgroundImage: "linear-gradient(30deg,#9999ff,white"}  } >
                            <TextBubble style={ {backgroundImage: "linear-gradient(30deg,DarkBlue,#4444FF"} }  >
                                <p>정상</p>
                            </TextBubble>
                            <NumberBox >
                            {not_errored}
                            </NumberBox>                            

                        </Widget>

                        <Widget  style={  {backgroundImage: "linear-gradient(30deg,#ff9999,white"}   }>
                            <TextBubble style={ {backgroundImage: "linear-gradient(30deg,Maroon,MediumVioletRed"} }>
                                <p>오류</p>
                            </TextBubble>
                            <NumberBox >
                            {errored}
                            </NumberBox>                            
                        </Widget>
                    </WidgetBox>
            </Background>
        </>
    )
*/
}

export default Devices
