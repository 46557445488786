import React from "react";
import styled from "styled-components";

const IconBox = styled.div `
display: flex;
flex-direction: column;
justify-content: center; 
margin-top: 20px;
margin-bottom: 0px;
border-radius: 10px;
width: 50px;
height: 50px;

font-size: 10px;

`
const InnerIconBox = styled.div `
display: flex;
justify-content: center;
`
const TextBox = styled.div `
display: flex;
flex-direction: column;
justify-content: center;
height: 20px;

font-size: 10px;
color: black;

`

function SidebarItem({ menu, isActive ,icon}) {

  return (
    <>
    <IconBox style={{ backgroundImage: isActive===true? 'linear-gradient(30deg, gray, white)':'linear-gradient(30deg, lightgray, white)'}} >
      <InnerIconBox>
      <menu.icon  size={30} color={ isActive===true?'white':'black'} title={menu.name}/>        
      </InnerIconBox>
    </IconBox>
    <TextBox>{menu.name}</TextBox>    
    </>   
  ) 
}

export default SidebarItem;